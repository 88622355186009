


:root {
  --off-white: #fafafa;
}
.nav {
  background-color: var(--off-white);
  position: sticky;
  top: 0px;
  width: 100%;
  height: 80px;
  z-index: 1000;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}

.nav-logo {
  height: 60px;
  width: 60px;
  border-radius: 30%;
}

.nav-logo:hover {
  cursor: pointer;
}

.nav .nav-content {
  max-width: 900px;
  padding: 0rem 3rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}


.nav-item {
  display: inline;
  margin-left: 2rem;
  color: #333;
}

.nav-item:hover {
  color: black;
  cursor: pointer;
}

.section {
}

.section-dark {
  background: #333;
  color: white;
}

.section-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
}

.front{
  background-color: #fafafa;  
  height: 80vh;
  padding-top: 20vh;
}

.front-picture{
  text-align: center;




}


.tile-content {
  display: grid;
  align-items: center;
  grid-template-columns: 49% 49%;
  grid-gap: 5px;
  /*background-color: #2196F3;*/
  padding: 10px;
}

.tile-content > div {
  /*background-color: rgba(255, 255, 255, 0.8);*/
  text-align: left;
  padding: 1 0px 0;
  font-size: 14px;
}



.skill-content-app{
  /*background-color: peachpuff;*/
}


.skill-content {
  display: flex;
  flex-direction: row;
  flex-basis: 50%;
  grid-gap: 5px;
  /*background-color: green;*/
  padding: 10px;
}

.skill{
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  /*background-color: lightslategray;*/
}

.proficiency{
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  flex-basis: 50%;
 /* background-color: lightcoral;*/

}

.quote{
  padding-top: 10px;
  text-align: center;
  font-size: 30px;
  font-style: italic;
}

.title {
  grid-row: 1/1;
}

.place {
  grid-row: 1/2
;
}
.date {
  grid-row: 2/2;
}

.location {
  grid-row: 3/3;
}

.locationtag {
  text-align: center;
  /*background-color: lawngreen;*/
  grid-row: 3/4;
}




.description {
  grid-row: 2/4;

}

.Profile {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.Picture > img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  max-height: 300px;
  border-radius: 30%;
}

.elinpic > img {
  margin-left: 1rem;
  max-width: 300px;
  max-height: 400px;
  border-radius: 30%;
  grid-row: 1/4;
}

/*===============================================*/

.project-content{
  display: grid;
  align-items: left;
  grid-template-columns: 49% 49%;
  grid-gap: 5px;
  /*background-color: #2196F3;*/
  padding: 10px;
}


.robotpic > img {
  margin-left: 1rem;
  margin-top: 1rem;
  max-width: 200px;
  max-height: 300px;
  border-radius: 30%;
  grid-row: 1/4;
}

.reactpic > img {
  margin-top: 1rem;
  margin-left: 1rem;
  max-width: 200px;
  max-height: 300px;
  border-radius: 7%;
  grid-row: 1/4;
}

.tekpic > img {
  padding: 20px;
  background-color: white;
  margin-left: 1.5rem;
  margin-top: 2rem;
  max-width: 200px;
  max-height: 300px;
  border-radius: 7%;
  grid-row: 1/4;
}


.projectdescription{
  grid-row: 1/4;
}

.links1{
  display: grid;
  align-items: left;
  grid-template-columns: 20% 20%;
  grid-gap: 5px;

}
/*===============================================*/

.About{
  margin-right: 2rem;
  max-width: 300px;
}

.Details{
  margin-left: 2rem;
  max-width: 300px;
}

.education{
  margin-top: 2rem;
}

.career{
  margin-top: 2rem;
}

.contactInfo{
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 30%;

}

.FacebookPicture{
  display: flex;

  justify-content: space-between;
  align-items: center;
  padding: 10px;


}

.GitlabPicture{
  display: flex;

  justify-content: space-between;
  align-items: center;
  padding: 10px;


}

.InstagramPicture {
  display: flex;

  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.LinkedinPicture {
  display: flex;

  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.ResumePicture {
  display: flex;

  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.EmailPicture {
  display: flex;

  justify-content: space-between;
  align-items: center;
  padding: 10px;


}

.InteractiveResume{
  text-align: center;
  margin-bottom: 20%;
}